import React from 'react';

const Faq = () => {
  return (
    <>
      <meta httpEquiv="refresh" content="0;url=/faq"/>
    </>
  );
};
export default Faq;
